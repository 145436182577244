import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga";
import { Divider, Stack, SvgIcon, Link, SpeedDialAction } from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import HomeIcon from "@mui/icons-material/Home";
import GamesIcon from "@mui/icons-material/Games";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import EmailIcon from "@mui/icons-material/Email";
import InfoIcon from "@mui/icons-material/Info";
import ContactForm from "subcomponents/contactForm";
import {
  MobileDrawerPaper,
  MobileDrawerListItemButton,
  MobileDrawerListItemIcon,
  MobileDrawerStack,
  MobileDrawerSpeedDial,
  SteamIcon,
  YoutubeIcon,
  LinkedInIcon,
  XIcon,
  FacebookIcon,
} from "shared/constGlobal";

export default function MobileDrawer({
  isDrawerOpened,
  handleCloseDrawer,
  setPageLoading,
}) {
  let navigate = useNavigate();

  const [isOpenContact, setisOpenContact] = useState(false);
  const handleDrawer = () => {
    handleCloseDrawer(false);
  };
  const handleHomeButton = () => {
    setPageLoading(true);
    navigate("/");
  };

  const handleGamesButton = () => {
    setPageLoading(true);
    navigate("/games");
  };

  const handleNewsButton = () => {
    setPageLoading(true);
    navigate("/news");
  };

  const handleCompanyButton = () => {
    setPageLoading(true);
    navigate("/company");
  };
  const handleContactUs = () => {
    setisOpenContact(!isOpenContact);
  };
  const handleYoutube = () => {
    ReactGA.event({
      category: "Links",
      action: "Youtube",
      label: "Youtube Server Redirect",
      value: 1,
    });
  };

  const handleFacebook = () => {
    ReactGA.event({
      category: "Links",
      action: "Facebook",
      label: "Facebook Server Redirect",
      value: 1,
    });
  };
  const handleLinkedIn = () => {
    ReactGA.event({
      category: "Links",
      action: "LinkedIn",
      label: "LinkedIn Redirect",
      value: 1,
    });
  };
  const handleX = () => {
    ReactGA.event({
      category: "Links",
      action: "X",
      label: "X Server Redirect",
      value: 1,
    });
  };
  const handleSteam = () => {
    ReactGA.event({
      category: "Links",
      action: "Steam",
      label: "Steam Store Redirect",
      value: 1,
    });
  };
  const drawerList = [
    {
      icon: <HomeIcon />,
      title: "Home",
      id: 0,

      onClick: handleHomeButton,
    },
    {
      icon: <GamesIcon />,
      title: "Games",
      id: 1,

      onClick: handleGamesButton,
    },
    {
      icon: <NewspaperIcon />,
      title: "News",
      id: 2,

      onClick: handleNewsButton,
    },
    {
      icon: <InfoIcon />,
      title: "Company",
      id: 3,

      onClick: handleCompanyButton,
    },
    {
      icon: <EmailIcon />,
      title: "Contact Us",
      id: 4,
      anchorId: "#ContactUs",
      onClick: handleContactUs,
    },
  ];
  const drawerSocialButtons = [
    {
      icon: (
        <Link
          href="https://store.steampowered.com/developer/Seacorp"
          aria-label="Link to the SCT Steam page"
          target="_blank"
          rel="noopener"
        >
          <SvgIcon alt="Steam Logo">
            <SteamIcon />
          </SvgIcon>
        </Link>
      ),
      name: "SCT on Steam",
      id: 0,
      onClick: handleSteam,
    },
    {
      icon: (
        <Link
          href="https://www.youtube.com/channel/UCh9_t29QZI3j0TMJAYOphxw"
          aria-label="Link to the SCT Youtube channel"
          target="_blank"
          rel="noopener"
        >
          <SvgIcon alt="Youtube Logo">
            <YoutubeIcon />
          </SvgIcon>
        </Link>
      ),
      name: "SCT on Youtube",
      id: 1,
      onClick: handleYoutube,
    },
    {
      icon: (
        <Link
          href="https://x.com/seacorptech"
          aria-label="Link to SCT on X"
          target="_blank"
          rel="noopener"
        >
          <SvgIcon alt="X Logo">
            <XIcon />
          </SvgIcon>
        </Link>
      ),
      name: "SCT on X",
      id: 2,
      onClick: handleX,
    },
    {
      icon: (
        <Link
          href="https://www.facebook.com/seacorpltd"
          aria-label="Link to the SCT Facebook page"
          target="_blank"
          rel="noopener"
        >
          <SvgIcon alt="Facebook Logo">
            <FacebookIcon />
          </SvgIcon>
        </Link>
      ),
      name: "SCT on Facebook",
      id: 3,
      onClick: handleFacebook,
    },
    {
      icon: (
        <Link
          href="https://www.linkedin.com/company/seacorp-technologies/"
          aria-label="Link to the SCT LinkedIn page"
          target="_blank"
          rel="noopener"
        >
          <SvgIcon alt="LinkedIn Logo">
            <LinkedInIcon />
          </SvgIcon>
        </Link>
      ),
      name: "SCT on LinkedIn",
      id: 4,
      onClick: handleLinkedIn,
    },
  ];

  return (
    <Fragment>
      <MobileDrawerPaper
        variant="persistent"
        open={isDrawerOpened}
        onClose={handleDrawer}
      >
        <Stack direction="column" justifyContent="center" alignItems="center">
          {drawerList.map((src) => (
            <MobileDrawerListItemButton component="a" onClick={src.onClick}>
              <MobileDrawerListItemIcon>{src.icon}</MobileDrawerListItemIcon>
            </MobileDrawerListItemButton>
          ))}
        </Stack>
        <Divider />
        <MobileDrawerStack
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
        >
          <MobileDrawerSpeedDial
            ariaLabel="Social Speed dial for Drawer"
            icon={<ShareIcon />}
            direction="down"
          >
            {drawerSocialButtons.map((src) => (
              <SpeedDialAction
                FabProps={{
                  size: "small",
                  sx: {
                    border: "3px solid #0084ff",
                    color: "#fff",
                    backgroundColor: "#0084ff",
                    "& svg": {
                      display: "block",
                    },
                    "&:hover": {
                      border: "3px solid #02d9fc",
                      backgroundColor: "#005cb2",
                    },
                  },
                }}
                key={src.id}
                icon={src.icon}
                arrow
                target="_blank"
                onClick={src.onClick}
              />
            ))}
          </MobileDrawerSpeedDial>
        </MobileDrawerStack>
      </MobileDrawerPaper>
      <ContactForm
        isContactOpened={isOpenContact}
        handleCloseContact={() => setisOpenContact(false)}
      />
    </Fragment>
  );
}
