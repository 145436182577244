import { useEffect, useState, Fragment } from "react";
import { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
import { TSParticles } from "shared/constGlobal";
import cubeSmall from "images/cubeSmall.webp";
import cubeMedium from "images/cubeMedium.webp";
import cubeLarge from "images/cubeLarge.webp";

export default function BackgroundParticles() {
  const [init, setInit] = useState(false);

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = (container) => {
    console.log(container);
  };

  return (
    <Fragment>
      {init && (
        <TSParticles
          id="tsparticles"
          particlesLoaded={particlesLoaded}
          options={{
            fpsLimit: 120,
            particles: {
              move: {
                direction: "none",
                enable: true,
                outModes: "out",
                random: false,
                speed: 0.2,
                straight: false,
              },
              number: { density: { enable: true, area: 315 }, value: 40 },
              opacity: {
                value: 1,
              },
              collisions: {
                enable: true,
                mode: "bounce",
              },
              shape: {
                type: "images",
                options: {
                  images: [
                    {
                      src: cubeSmall,
                      width: 44,
                      height: 44,
                    },
                    {
                      src: cubeMedium,
                      width: 64,
                      height: 64,
                    },
                    {
                      src: cubeLarge,
                      width: 72,
                      height: 72,
                    },
                  ],
                },
              },
              size: {
                value: 16,
              },
            },
            detectRetina: true,
          }}
        />
      )}
    </Fragment>
  );
}
