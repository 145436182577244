import React, { lazy, cloneElement } from "react";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { DialogContent, GlobalStyles, SvgIcon, TextField } from "@mui/material";
import { createRoot } from "react-dom/client";
import { styled } from "@mui/system";
import {
  Stack,
  Typography,
  SnackbarContent,
  CardHeader,
  Alert,
  IconButton,
  Link,
  Box,
  Container,
  LinearProgress,
  AppBar,
  Toolbar,
  SpeedDial,
  Button,
  Popper,
  Fab,
  Drawer,
  ListItemButton,
  ListItemIcon,
  DialogTitle,
  Dialog,
  DialogActions,
  Grid,
  Skeleton,
} from "@mui/material";
import { TextValidator } from "react-material-ui-form-validator";
import { ReactComponent as ErrorIcon } from "images/errorIcon.svg";
import Particles from "@tsparticles/react";
import BiCookie from "@meronex/icons/bi/BiCookie";
import CgFileDocument from "@meronex/icons/cg/CgFileDocument";
import animatorAvatar from "images/animatorAvatar.webp";
import marketingAvatar from "images/marketingAvatar.webp";
import engineProgrammerAvatar from "images/engineProgrammerAvatar.webp";
import environmentArtistAvatar from "images/environmentArtistAvatar.webp";
import { saveAs } from "file-saver";
import axios from "axios";
import LoadingButton from "@mui/lab/LoadingButton";
import { theme } from "styles/theme";

export const inputGlobalStyles = (
  <GlobalStyles
    styles={{
      ul: {
        margin: 0,
        padding: 0,
        listStyle: "none",
      },
      "&::selection": {
        color: theme.palette.tertiary.main,
        background: "#fafafa",
      },
    }}
  />
);

const container = document.getElementById("root");

export const root = createRoot(container);

export const trackingID = process.env.REACT_APP_GA_TRACKING_ID;

export const reportWebVitals = (onPerfEntry) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import("web-vitals").then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(onPerfEntry);
      getFID(onPerfEntry);
      getFCP(onPerfEntry);
      getLCP(onPerfEntry);
      getTTFB(onPerfEntry);
    });
  }
};

export const ExpandBody = styled(Box)(({ theme }) => ({
  marginLeft: 56,
}));

export const CollapseBody = styled(Box)(({ theme }) => ({
  marginLeft: 0,
}));

export const PageContainer = styled(Container)(({ theme }) => ({
  minHeight: "85vh",
  display: "flex",
  flexDirection: "column",
}));

export const ErrorStack = styled(Stack)(({ theme }) => ({
  marginTop: 335,
  marginBottom: 335,
}));

export const ErrorBackground = styled(ErrorIcon)(() => ({
  zIndex: 1,
  pointerEvents: "none",
  [theme.breakpoints.down("md")]: {
    height: "12.5em",
  },
  [theme.breakpoints.up("md")]: {
    height: "25em",
  },
}));

export const ErrorTypography = styled(Typography)(({ theme }) => ({
  zIndex: 1,
  fontWeight: "bold",
  color: "#fafafa",
  [theme.breakpoints.down("md")]: {
    fontSize: 12,
  },
  [theme.breakpoints.up("md")]: {
    fontSize: 15,
  },
}));

export const TSParticles = styled(Particles)(() => ({
  width: "100%",
  height: "100%",
  position: "absolute",
}));

export const HLSStreamHome = styled("video")(() => ({
  width: "100%",
  height: "100%",
  objectFit: "cover",
}));

export const CompanyInfoBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "auto",
}));

export const CompanyInfoTypography = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  [theme.breakpoints.down("md")]: {
    fontSize: 15,
  },
  [theme.breakpoints.up("md")]: {
    fontSize: 30,
  },
}));

export const CookieBannerAlert = styled(Alert)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.tertiary.main,
  position: "fixed",
  width: "50%",
  marginLeft: "25%",
  bottom: "2.5%",
  zIndex: theme.zIndex.drawer + 3,
  [theme.breakpoints.down("md")]: {
    position: "fixed",
    width: "90%",
    right: "5%",
    bottom: "2.5%",
    fontSize: "75%",
  },
  boxShadow:
    "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
}));

export const cookieBanner = [
  {
    id: 0,
    cookieTitle: "Cookie Notice",
    cookieLinkText: "Learn more about our cookie policy",
    cookieText:
      "We use cookies to personalise content and to analyse our traffic. By closing this banner or continuing to use our site you accept our use of cookies.",
  },
];

export const CookieBiCookie = styled(BiCookie)(({ theme }) => ({
  color: theme.palette.common.white,
}));

export const FooterGrid = styled(Box)(({ theme }) => ({
  position: "relative",
  marginTop: "auto",
  padding: theme.spacing(3, 2),
  backgroundColor: theme.palette.primary.main,
  boxShadow:
    "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
}));

export const FooterLink = styled(Link)(({ theme }) => ({
  color: "#fafafa",
  cursor: "pointer",
  fontWeight: "bold",
  [theme.breakpoints.down("md")]: {
    fontSize: 12,
  },
  [theme.breakpoints.up("md")]: {
    fontSize: 15,
  },
  "&:hover": {
    textDecoration: "none",
    color: theme.palette.secondary.main,
  },
}));

export const FooterTypography = styled(Typography)(({ theme }) => ({
  color: "#fafafa",
  fontWeight: "bold",
  [theme.breakpoints.down("md")]: {
    fontSize: 12,
  },
  [theme.breakpoints.up("md")]: {
    fontSize: 15,
  },
}));

export const FooterCgFileDocument = styled(CgFileDocument)(({ theme }) => ({
  color: theme.palette.secondary.main,
  [theme.breakpoints.down("md")]: {
    height: "2em",
    width: "2em",
  },
  [theme.breakpoints.up("md")]: {
    height: "2.5em",
    width: "2.5em",
  },
}));

export const generateTwoElements = (element) => {
  return [0, 1].map((value) =>
    cloneElement(element, {
      key: value,
    }),
  );
};

export const generateThreeElements = (element) => {
  return [0, 1, 2].map((value) =>
    cloneElement(element, {
      key: value,
    }),
  );
};

export const generateFourElements = (element) => {
  return [0, 1, 2, 3].map((value) =>
    cloneElement(element, {
      key: value,
    }),
  );
};

export const generateFiveElements = (element) => {
  return [0, 1, 2, 3, 4].map((value) =>
    cloneElement(element, {
      key: value,
    }),
  );
};

export const generateSixElements = (element) => {
  return [0, 1, 2, 3, 4, 5].map((value) =>
    cloneElement(element, {
      key: value,
    }),
  );
};

export const generateTwentyElements = (element) => {
  return [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
  ].map((value) =>
    cloneElement(element, {
      key: value,
    }),
  );
};

export const CompanyDesktop = lazy(
  () => import("subcomponents/Desktop/company"),
);

export const GameCardsDesktop = lazy(
  () => import("subcomponents/Desktop/gameCards"),
);

export const GameNewsDesktop = lazy(
  () => import("subcomponents/Desktop/gameNews"),
);

export const CompanyMobile = lazy(() => import("subcomponents/Mobile/company"));

export const GameCardsMobile = lazy(
  () => import("subcomponents/Mobile/gameCards"),
);

export const GameNewsMobile = lazy(
  () => import("subcomponents/Mobile/gameNews"),
);

export const GameCardsBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "auto",
}));

export const GlobalCardsBox = styled(Box)(() => ({
  position: "relative",
  margin: 25,
}));

export const TitleBox = styled(Box)(() => ({
  width: "100%",
  backgroundColor: theme.palette.primary.main,
  marginTop: 250,
  height: 250,
  borderRadius: 16,
}));

export const TitleBoxTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  paddingTop: 75,
  fontSize: 72,
  [theme.breakpoints.up("md")]: {
    fontSize: 72,
  },
}));

export const GameCardTypography = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  [theme.breakpoints.down("md")]: {
    fontSize: 15,
  },
  [theme.breakpoints.up("md")]: {
    fontSize: 30,
  },
}));

export const GameNewsBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "auto",
}));

export const GameNewsCardHeader = styled(CardHeader)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  backgroundImage: "linear-gradient(#02d9fc, #0197b0)",
}));

export const GameNewsTypography = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  [theme.breakpoints.down("md")]: {
    fontSize: 15,
  },
  [theme.breakpoints.up("md")]: {
    fontSize: 30,
  },
}));

export const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  margin: "auto",
  height: 5,
  zIndex: theme.zIndex.appBar + 1,
}));

export const HeaderAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  zIndex: theme.zIndex.drawer + 1,
}));

export const HeaderToolbar = styled(Toolbar)(({ theme }) => ({
  height: 88,
}));

export const HeaderImage = styled("img")(({ theme }) => ({
  width: 64,
  height: 64,
  marginRight: theme.spacing(2),
  pointerEvents: "none",
}));

export const HeaderFab = styled(Fab)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

export const HeaderButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(0.5),
  [theme.breakpoints.up("md")]: {
    fontSize: 12,
  },
}));

export const StyledSvgIcon = styled(SvgIcon)(({ theme }) => ({
  "&:hover": {
    "&:before": {
      bottom: -6,
    },

    transform: "scale(1.1)",
    boxShadow: "0 6px 20px 0 rgba(0,0,0,0.38)",
  },
}));

export { ReactComponent as SteamIcon } from "images/steamIcon.svg";
export { ReactComponent as FandomIcon } from "images/fandomIcon.svg";
export { ReactComponent as RedditIcon } from "images/redditIcon.svg";
export { ReactComponent as XIcon } from "images/xIcon.svg";
export { ReactComponent as FacebookIcon } from "images/facebookIcon.svg";
export { ReactComponent as YoutubeIcon } from "images/youtubeIcon.svg";
export { ReactComponent as DiscordIcon } from "images/discordIcon.svg";
export { ReactComponent as LinkedInIcon } from "images/linkedInIcon.svg";

export const HeaderSpeedDial = styled(SpeedDial)(({ theme }) => ({
  "&  .MuiSpeedDial-fab": {
    width: 48,
    height: 48,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    "& .MuiSvgIcon-root": { fontSize: 30 },
  },
  "&  .MuiSpeedDialAction-fab": {
    "& svg": {
      display: "block",
    },
  },
}));

export const HeroBannerVideoSlider = lazy(
  () => import("subcomponents/heroBannerVideoSlider"),
);

export const HeroBannerContent = lazy(
  () => import("subcomponents/heroBannerContent"),
);
export const GlobalPopper = styled(Popper)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 2,
}));

export const PopupSnackbarContent = styled(SnackbarContent)(({ theme }) => ({
  backgroundColor: theme.palette.tertiary.main,
  width: 20,
  color: "#fff",
  display: "block",
  textAlign: "center",
  boxShadow:
    "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
}));

export const PaperListItemIcon = styled(ListItemIcon)(() => ({
  color: "#fff",
}));

export const MobileDrawerPaper = styled(Drawer)(({ theme }) => ({
  "& .MuiPaper-root": {
    width: 56,
    overflow: "hidden",
    marginTop: theme.spacing(11),
    backgroundColor: "#2f4e6b",
    boxShadow:
      "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
  },
}));

export const MobileDrawerListItemButton = styled(ListItemButton)(() => ({
  height: 46,
  backgroundColor: theme.palette.tertiary.main,
  "&:hover": {
    borderTop: "3px solid #02d9fc",
    borderBottom: "3px solid #02d9fc",
    backgroundColor: "#005cb2",
  },
}));

export const MobileDrawerListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  padding: theme.spacing(3),
  color: "#fff",
}));

export const MobileDrawerStack = styled(Stack)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

export const MobileDrawerSpeedDial = styled(SpeedDial)(({ theme }) => ({
  "&  .MuiSpeedDial-fab": {
    height: 48,
    width: 48,
    "& .MuiSvgIcon-root": { fontSize: 30 },
  },
  "&  .MuiSpeedDialAction-fab": {
    "& svg": {
      display: "block",
    },
  },
}));

export const ScrollButton = styled(Box)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 2,
  position: "absolute",
  right: theme.spacing(7.5),
  [theme.breakpoints.down("md")]: {
    bottom: theme.spacing(15),
  },
  [theme.breakpoints.up("md")]: {
    bottom: theme.spacing(20),
  },
}));

export const ScrollFab = styled(Fab)(() => ({
  color: "#fff",
  border: "3px solid #0084ff",
  backgroundColor: theme.palette.tertiary.main,
  "&:hover": {
    border: "3px solid #02d9fc",
    backgroundColor: "#005cb2",
  },
}));

export const GlobalTitle = styled(DialogTitle)(() => ({
  fontWeight: "bold",
}));

export const GlobalSubTitle = styled(DialogTitle)(() => ({
  marginTop: -30,
}));

export const GlobalDialog = styled(Dialog)(() => ({
  "& .MuiPaper-root": {
    width: 600,
    backgroundColor: theme.palette.primary.main,
    boxShadow:
      "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
    overflow: "hidden",
  },
}));

export const LoginDialog = styled(Dialog)(() => ({
  "& .MuiPaper-root": {
    width: 600,
    backgroundColor: theme.palette.primary.main,
    boxShadow:
      "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
    overflow: "hidden",
  },
}));

export const SignInDialog = styled(Dialog)(() => ({
  "& .MuiPaper-root": {
    height: 685,
    width: 600,
    backgroundColor: theme.palette.primary.main,
    boxShadow:
      "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
    overflow: "hidden",
  },
}));

export const GlobalIconButton = styled(IconButton)(() => ({
  color: "#fff",
  position: "absolute",
  right: 8,
  top: 12,
  border: "3px solid #0084ff",
  backgroundColor: theme.palette.tertiary.main,
  "&:hover": {
    border: "3px solid #02d9fc",
    backgroundColor: "#005cb2",
  },
}));

export const GlobalTypography = styled(Typography)(() => ({
  fontWeight: "bold",
}));

export const GlobalLink = styled(Link)(({ theme }) => ({
  color: theme.palette.secondary.main,
  textDecoration: "none",
  cursor: "pointer",
  "&:hover": {
    textDecoration: "none",
    color: theme.palette.secondary.dark,
  },
}));

export const GlobalOverlayScrollbarsComponent = styled(
  OverlayScrollbarsComponent,
)(() => ({
  maxWidth: "100%",
  maxHeight: "100%",
  paddingRight: 40,
}));

export const LoginDialogContent = styled(DialogContent)(() => ({
  height: 178,
}));

export const GlobalTextValidator = styled(TextValidator)(() => ({
  "& .MuiFormHelperText-root": {
    fontWeight: "bold",
  },
}));

export const LoginTextField = styled(TextField)(() => ({
  "& .MuiFormHelperText-root": {
    fontWeight: "bold",
  },
}));

export const GlobalDialogActions = styled(DialogActions)(({ theme }) => ({
  margin: theme.spacing(2, 2),
}));

export const GlobalButton = styled(LoadingButton)(() => ({
  color: "#ffffff",
  border: "3px solid #0084ff",
  backgroundColor: theme.palette.tertiary.main,
  "&:hover": {
    border: "3px solid #02d9fc",
    backgroundColor: "#005cb2",
  },
}));

export const cookie = [
  {
    id: 0,
    title: "Cookie Policy for SEACORP TECHNOLOGIES LIMITED",
    subTitleOne: "What Are Cookies",
    subTitleTwo: "How We Use Cookies",
    subTitleThree: "Disabling Cookies",
    subTitleFour: "The Cookies We Set",
    subTitleFive: "Forms related cookies",
    subTitleSix: "Site preferences cookies",
    subTitleSeven: "Third Party Cookies",
    subTitleEight: "More Information",
    textOne:
      "This is the Cookie Policy for Seacorp Technologies\u002c accessible from ",
    textTwo:
      "As is common practice with almost all professional websites this site uses cookies\u002c which are tiny files that are downloaded to your computer\u002c to improve your experience\u002e This page describes what information they gather\u002c how we use it and why we sometimes need to store these cookies\u002e We will also share how you can prevent these cookies from being stored however this may downgrade or \u0027break\u0027 certain elements of the sites functionality\u002e",
    textThree:
      "We use cookies for a variety of reasons detailed below\u002e Unfortunately in most cases there are no industry standard options for disabling cookies without completely disabling the functionality and features they add to this site\u002e It is recommended that you leave on all cookies if you are not sure whether you need them or not in case they are used to provide a service that you use\u002e",
    textFour:
      "You can prevent the setting of cookies by adjusting the settings on your browser (see your browser Help for how to do this)\u002e Be aware that disabling cookies will affect the functionality of this and many other websites that you visit\u002e Disabling cookies will usually result in also disabling certain functionality and features of the this site\u002e Therefore it is recommended that you do not disable cookies\u002e",
    textFive:
      "When you submit data to through a form such as those found on contact pages or comment forms cookies may be set to remember your user details for future correspondence\u002e",
    textSix:
      "In order to provide you with a great experience on this site we provide the functionality to set your preferences for how this site runs when you use it\u002e In order to remember your preferences we need to set cookies so that this information can be called whenever you interact with a page is affected by your preferences\u002e",
    textSeven:
      "In some special cases we also use cookies provided by trusted third parties\u002e The following section details which third party cookies you might encounter through this site\u002e",
    textEight:
      "This site uses Google Analytics which is one of the most widespread and trusted analytics solution on the web for helping us to understand how you use the site and ways that we can improve your experience\u002e These cookies may track things such as how long you spend on the site and the pages that you visit so we can continue to produce engaging content\u002e",
    textNine:
      "For more information on Google Analytics cookies\u002c see the official Google Analytics page\u002e",
    textTen:
      "From time to time we test new features and make subtle changes to the way that the site is delivered\u002e When we are still testing new features these cookies may be used to ensure that you receive a consistent experience whilst on the site whilst ensuring we understand which optimisations our users appreciate the most\u002e",
    textEleven:
      "As we sell products it\u0027s important for us to understand statistics about how many of the visitors to our site actually make a purchase and as such this is the kind of data that these cookies will track\u002e This is important to you as it means that we can accurately make business predictions that allow us to monitor our advertising and product costs to ensure the best possible price\u002e",
    textTwelve:
      "Hopefully that has clarified things for you and as was previously mentioned if there is something that you aren\u0027t sure whether you need or not it\u0027s usually safer to leave cookies enabled in case it does interact with one of the features you use on our site\u002e",
    textThirteen:
      "However if you are still looking for more information then you can contact us through one of our preferred contact methods\u003a",
    textFourteen: "To contact us please ",
    textLinkOne: "https\u003a\u002f\u002fwww\u002eseacorptech\u002ecom\u002f",
    textLinkTwo: "use this contact form",
  },
];

export const BannerContentGrid = styled(Grid)(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  margin: "auto",
  zIndex: 3,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "16px",
  height: "75%",
  width: "50%",
  [theme.breakpoints.up("sm")]: {
    width: 850,
  },
  [theme.breakpoints.up("lg")]: {
    width: 1200,
  },
}));

export const BannerContentLogoStack = styled(Stack)(({ theme }) => ({
  pointerEvents: "none",
  [theme.breakpoints.down("md")]: {
    width: 122,
    height: 76,
  },
  [theme.breakpoints.up("md")]: {
    width: 244,
    height: 151,
  },
  [theme.breakpoints.up("lg")]: {
    width: 488,
    height: 302,
  },
}));

export const BannerContentTextStack = styled(Stack)(({ theme }) => ({
  width: 350,
  [theme.breakpoints.down("md")]: {
    width: 250,
  },

  [theme.breakpoints.up("lg")]: {
    padding: 15,
  },
}));

export const BannerContentTitleTypography = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    fontSize: 15,
  },
  [theme.breakpoints.up("md")]: {
    fontSize: 25,
  },
}));

export const BannerContentTypewriterTypography = styled(Typography)(
  ({ theme }) => ({
    color: "#02d9fc",
    [theme.breakpoints.down("md")]: {
      fontSize: 15,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 25,
    },
  }),
);

export const BannerContentSubTitleTypography = styled(Typography)(
  ({ theme }) => ({
    fontWeight: "bold",
    [theme.breakpoints.down("md")]: {
      fontSize: 12,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 15,
    },
  }),
);

export const BannerVideoSkeleton = styled(Skeleton)(({ theme }) => ({
  width: "100%",
  height: "100%",
}));

export const BannerVideoBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#263238",
  filter: "brightness(50%)",
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  overflow: "hidden",
}));

export const privacy = [
  {
    id: 0,
    title: "Privacy Policy for SEACORP TECHNOLOGIES LIMITED",
    subTitleOne: "Consent",
    subTitleTwo: "Information we collect",
    subTitleThree: "How we use your information",
    subTitleFour:
      "We use the information we collect in various ways\u002c including to\u003a",
    subTitleFive: "Log Files",
    subTitleSix: "Cookies and Web Beacons",
    subTitleSeven: "Advertising Partners Privacy Policies",
    subTitleEight: "Third Party Privacy Policies",
    subTitleNine: "CCPA Privacy Rights (Do Not Sell My Personal Information)",
    subTitleTen:
      "Under the CCPA\u002c among other rights\u002c California consumers have the right to\u003a",
    subTitleEleven: "GDPR Data Protection Rights",
    subTitleTwelve:
      "We would like to make sure you are fully aware of all of your data protection rights\u002e Every user is entitled to the following\u003a",
    subTitleThirteen: "Children\u0027s Information",
    textOne: "At Seacorp Technologies\u002c accessible from ",
    textTwo:
      "\u002c one of our main priorities is the privacy of our visitors\u002e This Privacy Policy document contains types of information that is collected and recorded by Seacorp Technologies and how we use it\u002e",
    textThree:
      "If you have additional questions or require more information about our Privacy Policy\u002c do not hesitate to contact us\u002e",
    textFour:
      "This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and\u002for collect in Seacorp Technologies\u002e This policy is not applicable to any information collected offline or via channels other than this website\u002e",
    textFive:
      "By using our website\u002c you hereby consent to our Privacy Policy and agree to its terms\u002e",
    textSix:
      "The personal information that you are asked to provide\u002c and the reasons why you are asked to provide it\u002c will be made clear to you at the point we ask you to provide your personal information\u002e",
    textSeven:
      "If you contact us directly\u002c we may receive additional information about you such as your name\u002c email address\u002c phone number\u002c the contents of the message and\u002for attachments you may send us\u002c and any other information you may choose to provide\u002e",
    textEight:
      "When you register for an Account\u002c we may ask for your contact information\u002c including items such as name\u002c company name\u002c address\u002c email address\u002c and telephone number\u002e",
    textNine: "Provide\u002c operate\u002c and maintain our website",
    textTen: "Improve\u002c personalize\u002c and expand our website",
    textEleven: "Understand and analyze how you use our website",
    textTwelve:
      "Develop new products\u002c services\u002c features\u002c and functionality",
    textThirteen:
      "Communicate with you\u002c either directly or through one of our partners\u002c including for customer service\u002c to provide you with updates and other information relating to the website\u002c and for marketing and promotional purposes",
    textFourteen: "Send you emails",
    textFifteen: "Find and prevent fraud",
    textSixteen:
      "Seacorp Technologies follows a standard procedure of using log files\u002e These files log visitors when they visit websites\u002e All hosting companies do this and a part of hosting services\u0027 analytics\u002e The information collected by log files include internet protocol (IP) addresses\u002c browser type\u002c Internet Service Provider (ISP)\u002c date and time stamp\u002c referring\u002fexit pages\u002c and possibly the number of clicks\u002e These are not linked to any information that is personally identifiable\u002e The purpose of the information is for analyzing trends\u002c administering the site\u002c tracking users\u0027 movement on the website\u002c and gathering demographic information\u002e",
    textSeventeen:
      "Like any other website\u002c Seacorp Technologies uses \u0027cookies\u0027\u002e These cookies are used to store information including visitors\u0027 preferences\u002c and the pages on the website that the visitor accessed or visited\u002e The information is used to optimize the users\u0027 experience by customizing our web page content based on visitors\u0027 browser type and\u002for other information\u002e",
    textEighteen:
      "You may consult this list to find the Privacy Policy for each of the advertising partners of Seacorp Technologies\u002e",
    textNineteen:
      "Third\u002dparty ad servers or ad networks uses technologies like cookies\u002c JavaScript\u002c or Web Beacons that are used in their respective advertisements and links that appear on Seacorp Technologies\u002c which are sent directly to users\u0027 browser\u002e They automatically receive your IP address when this occurs\u002e These technologies are used to measure the effectiveness of their advertising campaigns and\u002for to personalize the advertising content that you see on websites that you visit\u002e",
    textTwenty:
      "Note that Seacorp Technologies has no access to or control over these cookies that are used by third\u002dparty advertisers\u002e",
    textTwentyOne:
      "Seacorp Technologies Privacy Policy does not apply to other advertisers or websites\u002e Thus\u002c we are advising you to consult the respective Privacy Policies of these third\u002dparty ad servers for more detailed information\u002e It may include their practices and instructions about how to opt\u002dout of certain options\u002e",
    textTwentyTwo:
      "You can choose to disable cookies through your individual browser options\u002e To know more detailed information about cookie management with specific web browsers\u002c it can be found at the browsers\u0027 respective websites\u002e",
    textTwentyThree:
      "Request that a business that collects a consumer\u0027s personal data disclose the categories and specific pieces of personal data that a business has collected about consumers\u002e",
    textTwentyFour:
      "Request that a business delete any personal data about the consumer that a business has collected\u002e",
    textTwentyFive:
      "Request that a business that sells a consumer\u0027s personal data\u002c not sell the consumer\u0027s personal data\u002e",
    textTwentySix:
      "If you make a request\u002c we have one month to respond to you\u002e If you would like to exercise any of these rights\u002c please contact us\u002e",
    textTwentySeven:
      "The right to access \u2013 You have the right to request copies of your personal data\u002e We may charge you a small fee for this service\u002e",
    textTwentyEight:
      "The right to rectification \u2013 You have the right to request that we correct any information you believe is inaccurate\u002e You also have the right to request that we complete the information you believe is incomplete\u002e",
    textTwentyNine:
      "The right to erasure \u2013 You have the right to request that we erase your personal data\u002c under certain conditions\u002e",
    textThirty:
      "The right to restrict processing \u2013 You have the right to request that we restrict the processing of your personal data\u002c under certain conditions\u002e",
    textThirtyOne:
      "The right to object to processing \u2013 You have the right to object to our processing of your personal data\u002c under certain conditions\u002e",
    textThirtyTwo:
      "The right to data portability \u2013 You have the right to request that we transfer the data that we have collected to another organization\u002c or directly to you\u002c under certain conditions\u002e",
    textThirtyThree:
      "If you make a request\u002c we have one month to respond to you\u002e If you would like to exercise any of these rights\u002c please contact us\u002e",
    textThirtyFour:
      "Another part of our priority is adding protection for children while using the internet\u002e We encourage parents and guardians to observe\u002c participate in\u002c and\u002for monitor and guide their online activity\u002e",
    textThirtyFive:
      "Seacorp Technologies does not knowingly collect any Personal Identifiable Information from children under the age of 13\u002e If you think that your child provided this kind of information on our website\u002c we strongly encourage you to contact us such information from our records\u002e",
    textLinkOne: "https\u003a\u002f\u002fwww\u002eseacorptech\u002ecom\u002f",
  },
];

export const terms = [
  {
    id: 0,
    title: "Terms of Use for SEACORP TECHNOLOGIES LIMITED",
    subTitleOne: "Terms",
    subTitleTwo: "Use License",
    subTitleThree: "Disclaimer",
    subTitleFour: "Limitations",
    subTitleFive: "Revisions and Errata",
    subTitleSix: "Links",
    subTitleSeven: "Site Terms of Use Modifications",
    subTitleEight: "Your Privacy",
    subTitleNine: "Governing Law",
    textOne: "By accessing this Website\u002c accessible from ",
    textTwo:
      "\u002c you are agreeing to be bound by these Website Terms and Conditions of Use and agree that you are responsible for the agreement with any applicable local laws\u002e If you disagree with any of these terms\u002c you are prohibited from accessing this site\u002e The materials contained in this Website are protected by copyright and trade mark law\u002e",
    textThree:
      "Permission is granted to temporarily download one copy of the materials on SEACORP TECHNOLOGIES LIMITED\u0027s Website for personal\u002c non\u002dcommercial transitory viewing only\u002e This is the grant of a license\u002c not a transfer of title\u002c and under this license you may not\u003a",
    textFour: "modify or copy the materials\u003b",
    textFive:
      "use the materials for any commercial purpose or for any public display\u003b",
    textSix:
      "attempt to reverse engineer any software contained on SEACORP TECHNOLOGIES LIMITED\u0027s Website\u003b",
    textSeven:
      "remove any copyright or other proprietary notations from the materials\u003b or",
    textEight:
      "transferring the materials to another person or \u0022mirror\u0022 the materials on any other server\u002e",
    textNine:
      "This will let SEACORP TECHNOLOGIES LIMITED to terminate upon violations of any of these restrictions\u002e Upon termination\u002c your viewing right will also be terminated and you should destroy any downloaded materials in your possession whether it is printed or electronic format\u002e",
    textTen:
      "All the materials on SEACORP TECHNOLOGIES LIMITED\u0027s Website are provided \u0022as is\u0022\u002e SEACORP TECHNOLOGIES LIMITED makes no warranties\u002c may it be expressed or implied\u002c therefore negates all other warranties\u002e Furthermore\u002c SEACORP TECHNOLOGIES LIMITED does not make any representations concerning the accuracy or reliability of the use of the materials on its Website or otherwise relating to such materials or any sites linked to this Website\u002e",
    textEleven:
      "SEACORP TECHNOLOGIES LIMITED or its suppliers will not be hold accountable for any damages that will arise with the use or inability to use the materials on SEACORP TECHNOLOGIES LIMITED\u0027s Website\u002c even if SEACORP TECHNOLOGIES LIMITED or an authorize representative of this Website has been notified\u002c orally or written\u002c of the possibility of such damage\u002e Some jurisdiction does not allow limitations on implied warranties or limitations of liability for incidental damages\u002c these limitations may not apply to you\u002e",
    textTwelve:
      "The materials appearing on SEACORP TECHNOLOGIES LIMITED\u0027s Website may include technical\u002c typographical\u002c or photographic errors\u002e SEACORP TECHNOLOGIES LIMITED will not promise that any of the materials in this Website are accurate\u002c complete\u002c or current\u002e SEACORP TECHNOLOGIES LIMITED may change the materials contained on its Website at any time without notice\u002e SEACORP TECHNOLOGIES LIMITED does not make any commitment to update the materials\u002e",
    textThirteen:
      "SEACORP TECHNOLOGIES LIMITED has not reviewed all of the sites linked to its Website and is not responsible for the contents of any such linked site\u002e The presence of any link does not imply endorsement by SEACORP TECHNOLOGIES LIMITED of the site\u002e The use of any linked website is at the user\u0027s own risk\u002e",
    textFourteen:
      "SEACORP TECHNOLOGIES LIMITED may revise these Terms of Use for its Website at any time without prior notice\u002e By using this Website\u002c you are agreeing to be bound by the current version of these Terms and Conditions of Use\u002e",
    textFifteen: "Please read our ",
    textSixteen:
      "Any claim related to SEACORP TECHNOLOGIES LIMITED\u0027s Website shall be governed by the laws of the United Kingdom of Great Britain without regards to its conflict of law provisions\u002e",
    textLinkOne: "https\u003a\u002f\u002fwww\u002eseacorptech\u002ecom\u002f",
    textLinkTwo: "Privacy Policy",
  },
];

export const aboutText = [
  {
    id: 0,
    text: "Seacorp Technologies started as any good startup... in a small garage in 2014 and was officially incorporated June 15th 2015. Since then SCT has grown to develop multiple titles in multiple genres achieving global top selling positions on a regular basis reaching into the millions in unique visitors and tens of thousands of paying customers. We have continued to strive, improve and grow as a creative indie game developer on the South Coast of the UK.",
  },
  {
    id: 1,
    text: "We are an enthusiastic team of talented developers covering both 2D and 3D fields alongside vast experience in multiple game engines and programming. We strive to create interactive unique user experiences from the ground up from the drawing board to full product launch and beyond with customer support and regular updates.",
  },
];

export const careers = [
  {
    altAvatar: "3D Animator",
    avatar: animatorAvatar,
    job: "3D Animator",
    loadingID: 0,
    loadedID: 0,
  },
  {
    altAvatar: "Marketing & Sales",
    avatar: marketingAvatar,
    job: "Marketing & Sales",
    loadingID: 1,
    loadedID: 1,
  },
  {
    altAvatar: "Engine Programmer",
    avatar: engineProgrammerAvatar,
    job: "Engine Programmer",
    loadingID: 2,
    loadedID: 2,
  },
  {
    altAvatar: "Environment Artist",
    avatar: environmentArtistAvatar,
    job: "Environment Artist",
    loadingID: 3,
    loadedID: 3,
  },
];

export const companyTabs = [
  {
    label: "About",
    tabId: "1",
  },
  {
    label: "Press",
    tabId: "2",
  },
  {
    label: "Resources",
    tabId: "3",
  },
  {
    label: "Career Opportunities",
    tabId: "4",
  },
  {
    label: "Videos",
    tabId: "5",
  },
  {
    label: "Gallery",
    tabId: "6",
  },
];

export const cmsTabs = [
  {
    label: "Buttons",
    tabId: "1",
  },
  {
    label: "Other",
    tabId: "2",
  },
  {
    label: "Other",
    tabId: "3",
  },
  {
    label: "Other",
    tabId: "4",
  },
  {
    label: "Other",
    tabId: "5",
  },
  {
    label: "Other",
    tabId: "6",
  },
];

export const pressLinks = [
  {
    id: 0,
    primaryText: (
      <Link
        href="https://www.pcgamer.com/massive-half-life-2-add-on-prospekt-releases-february/"
        target="_blank"
        rel="noopener"
        underline="none"
      >
        Massive Half-Life 2 add-on Prospekt releases February
      </Link>
    ),
    secondaryText: "By PC Gamer",
  },
  {
    id: 1,
    primaryText: (
      <Link
        href="https://www.eurogamer.net/fan-mod-half-life-prospect-dated-for-february-valve-approved"
        target="_blank"
        rel="noopener"
        underline="none"
      >
        Valve-approved fan-made Half-Life expansion Prospekt dated for February
      </Link>
    ),
    secondaryText: "By Eurogamer",
  },
];

export const savePressKit = () => {
  axios({
    url: process.env.REACT_APP_DOWNLOAD_SERVER_PRESS_KIT,
    method: "GET",
    responseType: "blob",
  }).then((response) => {
    console.log(response.data);
    saveAs(response.data, "company_press_kit.zip");
  });
};

export const saveScreenshots = () => {
  axios({
    url: process.env.REACT_APP_DOWNLOAD_SERVER_GAME_SCREENSHOTS,
    method: "GET",
    responseType: "blob",
  }).then((response) => {
    console.log(response.data);
    saveAs(response.data, "game_screenshots.zip");
  });
};

export const saveConceptArt = () => {
  axios({
    url: process.env.REACT_APP_DOWNLOAD_SERVER_CONCEPT_ART,
    method: "GET",
    responseType: "blob",
  }).then((response) => {
    console.log(response.data);
    saveAs(response.data, "game_concept_art.zip");
  });
};

export const saveVideos = () => {
  axios({
    url: process.env.REACT_APP_DOWNLOAD_SERVER_GAME_VIDEOS,
    method: "GET",
    responseType: "blob",
  }).then((response) => {
    console.log(response.data);
    saveAs(response.data, "game_videos.zip");
  });
};

export const extrasLinks = [
  {
    id: 0,
    primaryText: (
      <Link onClick={saveVideos} underline="none">
        {"Download official game videos (.zip)"}
      </Link>
    ),
  },
  {
    id: 1,
    primaryText: (
      <Link onClick={saveConceptArt} underline="none">
        {"Download official game concept art (.zip)"}
      </Link>
    ),
  },
  {
    id: 2,
    primaryText: (
      <Link onClick={saveScreenshots} underline="none">
        {"Download official game screenshots (.zip)"}
      </Link>
    ),
  },
];

export const downloadsLinks = [
  {
    id: 0,
    primaryText: (
      <Link onClick={savePressKit} underline="none">
        {"Download company press kit (.zip)"}
      </Link>
    ),
  },
];
