import React, { useState, useEffect, Fragment } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { createTheme } from "@mui/system";
import ReactGA from "react-ga";
import {
  useMediaQuery,
  Grid,
  Stack,
  Fade,
  SvgIcon,
  Link,
  SpeedDialAction,
} from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import HomeIcon from "@mui/icons-material/Home";
import GamesIcon from "@mui/icons-material/Games";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import InfoIcon from "@mui/icons-material/Info";
import EmailIcon from "@mui/icons-material/Email";
import HeaderLogo from "images/headerLogo.webp";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import ContactForm from "subcomponents/contactForm";
import MobileDrawer from "./mobileDrawer";
import {
  HeaderAppBar,
  BorderLinearProgress,
  HeaderToolbar,
  HeaderImage,
  HeaderFab,
  HeaderButton,
  HeaderSpeedDial,
  SteamIcon,
  YoutubeIcon,
  XIcon,
  FacebookIcon,
  LinkedInIcon,
} from "shared/constGlobal";
import { fetchContents } from "shared/fetchContents";

export default function Header({ setExpandBody, setCollapseBody }) {
  const theme = createTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const [pageLoading, setPageLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(true);
  const [headerLogo, setHeaderLogo] = useState(false);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [isOpenContact, setIsOpenContact] = useState(false);
  const [hideMenuIcon, setHideMenuIcon] = useState(false);
  const [showMenuIcon, setShowMenuIcon] = useState(true);

  const [content, setContent] = useState({
    content: {
      header: [
        {
          button: ["", "", ""],
          socialbuttontooltip: ["", "", "", "", ""],
        },
      ],
    },
  });

  const headerContentEN = content?.content?.header[0];

  let location = useLocation();
  let navigate = useNavigate();

  useEffect(() => {
    if (location.pathname !== "/") {
      setHeaderLogo(true);
    } else {
      setHeaderLogo(false);
    }
  }, [location.pathname]);

  const handleOpenDrawer = () => {
    setIsOpenDrawer(true);
    setHideMenuIcon(true);
    setShowMenuIcon(false);
    setCollapseBody(false);
    setExpandBody(true);
  };
  const handleCloseDrawer = () => {
    setIsOpenDrawer(false);
    setHideMenuIcon(false);
    setShowMenuIcon(true);
    setExpandBody(false);
    setCollapseBody(true);
  };

  const handleHomeButton = () => {
    setPageLoading(true);
    navigate("/");
  };

  const handleGamesButton = () => {
    setPageLoading(true);
    navigate("/games");
  };

  const handleNewsButton = () => {
    setPageLoading(true);
    navigate("/news");
  };

  const handleCompanyButton = () => {
    setPageLoading(true);
    navigate("/company");
  };

  const handleYoutube = () => {
    ReactGA.event({
      category: "Links",
      action: "Youtube",
      label: "Youtube Redirect",
      value: 1,
    });
  };

  const handleFacebook = () => {
    ReactGA.event({
      category: "Links",
      action: "Facebook",
      label: "Facebook Redirect",
      value: 1,
    });
  };

  const handleLinkedIn = () => {
    ReactGA.event({
      category: "Links",
      action: "LinkedIn",
      label: "LinkedIn Redirect",
      value: 1,
    });
  };

  const handleX = () => {
    ReactGA.event({
      category: "Links",
      action: "X",
      label: "X Redirect",
      value: 1,
    });
  };

  const handleSteam = () => {
    ReactGA.event({
      category: "Links",
      action: "Steam",
      label: "Steam Store Redirect",
      value: 1,
    });
  };

  const handleContactUs = () => {
    setIsOpenContact(!isOpenContact);
    setPageLoading(false);
  };

  const headerButtons = [
    {
      onClick: handleHomeButton,
      icon: <HomeIcon />,
      title: "Home",
      id: 0,
    },
    {
      onClick: handleGamesButton,
      icon: <GamesIcon />,
      title: "Games",
      id: 0,
    },
    {
      onClick: handleNewsButton,
      icon: <NewspaperIcon />,
      title: "News",
      id: 1,
    },
    {
      onClick: handleCompanyButton,
      icon: <InfoIcon />,
      title: "Company",
      id: 1,
    },
    {
      onClick: handleContactUs,
      icon: <EmailIcon />,
      title: "Contact Us",
      id: 2,
    },
  ];

  const headerSocialButtons = [
    {
      icon: (
        <Link
          href="https://store.steampowered.com/developer/Seacorp"
          aria-label="Link to the SCT Steam page"
          target="_blank"
          rel="noopener"
        >
          <SvgIcon alt="Steam Logo">
            <SteamIcon />
          </SvgIcon>
        </Link>
      ),
      text: headerContentEN?.socialbuttontooltip[0],
      id: 0,
      onClick: handleSteam,
      handleCloseDrawer,
    },
    {
      icon: (
        <Link
          href="https://www.youtube.com/channel/UCh9_t29QZI3j0TMJAYOphxw"
          aria-label="Link to the SCT Youtube channel"
          target="_blank"
          rel="noopener"
        >
          <SvgIcon alt="Youtube Logo">
            <YoutubeIcon />
          </SvgIcon>
        </Link>
      ),
      text: headerContentEN?.socialbuttontooltip[1],
      id: 1,
      onClick: handleYoutube,
    },
    {
      icon: (
        <Link
          href="https://x.com/seacorptech"
          aria-label="Link to SCT on X"
          target="_blank"
          rel="noopener"
        >
          <SvgIcon alt="X Logo">
            <XIcon />
          </SvgIcon>
        </Link>
      ),
      text: headerContentEN?.socialbuttontooltip[2],
      id: 2,
      onClick: handleX,
    },
    {
      icon: (
        <Link
          href="https://www.facebook.com/seacorpltd"
          aria-label="Link to the SCT Facebook page"
          target="_blank"
          rel="noopener"
        >
          <SvgIcon alt="Facebook Logo">
            <FacebookIcon />
          </SvgIcon>
        </Link>
      ),
      text: headerContentEN?.socialbuttontooltip[3],
      id: 3,
      onClick: handleFacebook,
    },
    {
      icon: (
        <Link
          href="https://www.linkedin.com/company/seacorp-technologies/"
          aria-label="Link to the SCT LinkedIn page"
          target="_blank"
          rel="noopener"
        >
          <SvgIcon alt="LinkedIn Logo">
            <LinkedInIcon />
          </SvgIcon>
        </Link>
      ),
      text: headerContentEN?.socialbuttontooltip[4],
      id: 4,
      onClick: handleLinkedIn,
    },
  ];

  useEffect(() => {
    fetchContents(setContent, setContentLoading);
    setTimeout(() => {
      setPageLoading(false);
    }, 500);
  }, [location]);

  return (
    <Fragment>
      <HeaderAppBar position="fixed" elevation={24}>
        {pageLoading && <BorderLinearProgress color="secondary" />}
        <HeaderToolbar>
          {isMobile ? (
            <Grid container justifyContent="space-between" alignItems="center">
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                {headerLogo && (
                  <Fade in={true}>
                    <HeaderImage
                      src={HeaderLogo}
                      width="100%"
                      height="100%"
                      alt="logo"
                    />
                  </Fade>
                )}
              </Stack>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                {!hideMenuIcon && (
                  <HeaderFab
                    aria-label="Show Menu"
                    onClick={handleOpenDrawer}
                    size="small"
                  >
                    <MenuIcon />
                  </HeaderFab>
                )}
                {!showMenuIcon && (
                  <HeaderFab
                    aria-label="Close Menu"
                    onClick={handleCloseDrawer}
                    size="small"
                  >
                    <CloseIcon />
                  </HeaderFab>
                )}
              </Stack>
            </Grid>
          ) : (
            <Grid container justifyContent="space-between" alignItems="center">
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                {headerLogo && (
                  <Fade in={true}>
                    <HeaderImage
                      src={HeaderLogo}
                      width="100%"
                      height="100%"
                      alt="logo"
                    />
                  </Fade>
                )}
                {headerButtons.map((src, index) => (
                  <HeaderButton
                    variant="contained"
                    onClick={src.onClick}
                    startIcon={src.icon}
                    key={index}
                    href={src.anchorId}
                  >
                    {src.title}
                  </HeaderButton>
                ))}
              </Stack>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <HeaderSpeedDial
                  ariaLabel="Speed dial social tooltip for Header"
                  icon={<ShareIcon />}
                  direction="left"
                  color="primary"
                >
                  {headerSocialButtons.map((src, index) => (
                    <SpeedDialAction
                      key={index}
                      icon={src.icon}
                      tooltipTitle={src.text}
                      arrow
                      target="_blank"
                      onClick={src.onClick}
                    />
                  ))}
                </HeaderSpeedDial>
              </Stack>
            </Grid>
          )}
        </HeaderToolbar>
      </HeaderAppBar>
      {isMobile ? (
        <MobileDrawer
          loading={pageLoading}
          setPageLoading={setPageLoading}
          isDrawerOpened={isOpenDrawer}
          handleCloseDrawer={() => setIsOpenDrawer(false)}
        />
      ) : null}
      <ContactForm
        isContactOpened={isOpenContact}
        handleCloseContact={() => setIsOpenContact(false)}
      />
    </Fragment>
  );
}
