import React, { Suspense } from "react";
import { Fade } from "react-awesome-reveal";
import { createTheme } from "@mui/system";
import { useMediaQuery } from "@mui/material";
import {
  CompanyInfoBox,
  TitleBox,
  TitleBoxTypography,
  CompanyMobile,
  CompanyDesktop,
} from "shared/constGlobal";

export default function CompanyInfo({ setOverflow }) {
  const theme = createTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Fade cascade damping={0.1}>
      <CompanyInfoBox>
        <TitleBox>
          <TitleBoxTypography align="center" variant="h1" component="div">
            COMPANY INFORMATION
          </TitleBoxTypography>
        </TitleBox>
        <Suspense fallback={null}>
          {isMobile ? (
            <CompanyMobile setOverflow={setOverflow} />
          ) : (
            <CompanyDesktop setOverflow={setOverflow} />
          )}
        </Suspense>
      </CompanyInfoBox>
    </Fade>
  );
}
