import React, { useState, useEffect, Fragment } from "react";
import { Scrollbar } from "components/scrollbar";
import { useLocation, Route, Routes } from "react-router-dom";
import ReactGA from "react-ga";
import { createTheme } from "@mui/system";
import { useMediaQuery } from "@mui/material";
import { trackingID, reportWebVitals } from "shared/constGlobal";
import Header from "./components/header";
import BackgroundParticles from "./components/backgroundParticles";
import Home from "./pages/home";
import Company from "./pages/company";
import Games from "./pages/games";
import News from "./pages/news";
import Error from "./pages/error";
import ContentManagementSystem from "./pages/contentManagementSystem";
import Footer from "./components/footer";
import CookieBanner from "./components/cookieBanner";
import BackToTop from "./components/backToTop";
import { ExpandBody, CollapseBody } from "shared/constGlobal";

ReactGA.initialize(trackingID);
export default function App() {
  const theme = createTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [bodyWidth, setBodyWidth] = useState(false);
  const { initBodyOverlayScrollbars, setOverflow } = Scrollbar();
  const expandBody = () => {
    setBodyWidth(true);
  };

  const collapseBody = () => {
    setBodyWidth(false);
  };

  let location = useLocation();
  useEffect(() => {
    ReactGA.set({ dimension1: "online" });
    ReactGA.ga("send", "pageview");
    reportWebVitals(({ id, name, value }) =>
      ReactGA.event({
        action: name,
        category: "Web Vitals",
        label: id,
        nonInteraction: true,
        value: Math.round(name === "CLS" ? value * 1000 : value),
      }),
    );
  }, [location]);

  useEffect(() => {
    initBodyOverlayScrollbars(document.body);
  }, [initBodyOverlayScrollbars]);

  return (
    <Fragment>
      {location.pathname !== "/" && <BackgroundParticles />}
      {location.pathname !== "/cms" && location.pathname !== "/cms/" && (
        <Fragment>
          <Header setExpandBody={expandBody} setCollapseBody={collapseBody} />
        </Fragment>
      )}

      {isMobile ? (
        <Fragment>
          {bodyWidth ? (
            <ExpandBody>
              <Routes>
                <Route path="/" element={<Home />} exact />
                <Route
                  path="/company"
                  element={<Company setOverflow={setOverflow} />}
                />
                <Route path="/games" element={<Games />} />
                <Route path="/news" element={<News />} />
                <Route path="/cms" element={<ContentManagementSystem />} />
                <Route path="*" element={<Error />} />
              </Routes>
            </ExpandBody>
          ) : (
            <CollapseBody>
              <Routes>
                <Route path="/" element={<Home />} exact />
                <Route
                  path="/company"
                  element={<Company setOverflow={setOverflow} />}
                />
                <Route path="/games" element={<Games />} />
                <Route path="/news" element={<News />} />
                <Route path="/cms" element={<ContentManagementSystem />} />
                <Route path="*" element={<Error />} />
              </Routes>
            </CollapseBody>
          )}
        </Fragment>
      ) : (
        <Routes>
          <Route path="/" element={<Home />} exact />
          <Route
            path="/company"
            element={<Company setOverflow={setOverflow} />}
          />
          <Route path="/games" element={<Games />} />
          <Route path="/news" element={<News />} />
          <Route path="/cms" element={<ContentManagementSystem />} />
          <Route path="*" element={<Error />} />
        </Routes>
      )}

      {location.pathname !== "/cms" && location.pathname !== "/cms/" && (
        <Fragment>
          <Footer />
          <CookieBanner />
          <BackToTop />
        </Fragment>
      )}
    </Fragment>
  );
}
